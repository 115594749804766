// transformers/tunn3l.ts
import type {
  Competition,
  Event,
  Round,
  Battle,
  Flight,
  Team,
  TeamLite
} from '@/interfaces/competition'
import type { ScoreObject } from '@/interfaces/tunn3l'

function mapDiscipline(discipline: string): Event['discipline'] {
  switch (discipline) {
    case 'dynamic_solo':
      return 'solospeed'
    case 'dynamic_2w':
      return 'd2w'
    case 'dynamic_4w':
      return 'd4w'
    case 'fs_4w':
      return 'fs4'
    case 'fs_8w':
      return 'fs8'
    case 'vfs_4w':
      return 'vfs'
    case 'freestyle_solo':
      return 'freestyle'
    default:
      return ''
  }
}

function mapFormatType(discipline: string): Event['format_type'] {
  switch (discipline) {
    case 'dynamic_solo':
    case 'dynamic_2w':
    case 'dynamic_4w':
      return 'qualifications-tournament_bracket'
    case 'fs_4w':
    case 'fs_8w':
    case 'vfs_4w':
    case 'freestyle':
      return 'standard_rounds'
    default:
      return 'standard_rounds'
  }
}

// function mapFormatType(
//   discipline: string,
//   nb_rounds: number,
//   nb_rounds_qualif: number
// ): Event['format_type'] {
//   if (discipline === 'freestyle') {
//     return 'standard_rounds'
//   }
//   if (nb_rounds === 0 && nb_rounds_qualif > 0) {
//     return 'qualifications-tournament_bracket'
//   } else {
//     return 'standard_rounds'
//   }
// }

function mapFlightType(type: string): Round['flight_type'] {
  switch (type) {
    case 'S':
      return 'speed'
    case 'F':
      return 'free'
    case 'C':
      return 'compulsory'
    case 'R':
      return 'standard'
    default:
      return ''
  }
}

const configurationMap: Record<string, Record<string, any>> = {
  dynamic_solo: {
    speed: {
      bust_value: 3,
      moves_per_page: 4,
      page_count: 1,
      skip_value: 20,
      flight_structure: [
        { type: 'entrance', name: 'Entrance' },
        { type: 'snake', name: 'Snake', page: 1 },
        { type: 'connector', name: 'Connector', page: 1 },
        { type: 'vertical', name: 'Vertical', page: 1 },
        { type: 'mixer', name: 'Mixer 1', page: 1 },
        { type: 'mixer', name: 'Mixer 2', page: 1 },
        { type: 'exit', name: 'Exit' }
      ],
      bust_type: {
        entrance: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        snake: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        connector: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        vertical: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' }
        ],
        mixer: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        exit: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' }
        ]
      }
    }
  },
  dynamic_2w: {
    speed: {
      bust_value: 5,
      moves_per_page: 3,
      page_count: 3,
      skip_value: 20,
      flight_structure: [
        { type: 'entrance', name: 'Entrance' },
        { type: 'snake', name: 'Snake 1', page: 1 },
        { type: 'connector', name: 'Connector 1', page: 1 },
        { type: 'vertical', name: 'Vertical 1', page: 1 },
        { type: 'mixer', name: 'Mixer 1', page: 1 },
        { type: 'snake', name: 'Snake 2', page: 2 },
        { type: 'connector', name: 'Connector 2', page: 2 },
        { type: 'vertical', name: 'Vertical 2', page: 2 },
        { type: 'mixer', name: 'Mixer 2', page: 2 },
        { type: 'snake', name: 'Snake 3', page: 3 },
        { type: 'connector', name: 'Connector 3', page: 3 },
        { type: 'vertical', name: 'Vertical 3', page: 3 },
        { type: 'mixer', name: 'Mixer 3', page: 3 },
        { type: 'exit', name: 'Exit' }
      ],
      bust_type: {
        entrance: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        snake: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        connector: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        vertical: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        mixer: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        exit: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ]
      }
    },
    free: {
      duration: 60
    }
  },
  dynamic_4w: {
    speed: {
      bust_value: 5,
      moves_per_page: 3,
      page_count: 3,
      skip_value: 20,
      flight_structure: [
        { type: 'entrance', name: 'Entrance' },
        { type: 'snake', name: 'Snake 1', page: 1 },
        { type: 'connector', name: 'Connector 1', page: 1 },
        { type: 'vertical', name: 'Vertical 1', page: 1 },
        { type: 'mixer', name: 'Mixer 1', page: 1 },
        { type: 'snake', name: 'Snake 2', page: 2 },
        { type: 'connector', name: 'Connector 2', page: 2 },
        { type: 'vertical', name: 'Vertical 2', page: 2 },
        { type: 'mixer', name: 'Mixer 2', page: 2 },
        { type: 'snake', name: 'Snake 3', page: 3 },
        { type: 'connector', name: 'Connector 3', page: 3 },
        { type: 'vertical', name: 'Vertical 3', page: 3 },
        { type: 'mixer', name: 'Mixer 3', page: 3 },
        { type: 'exit', name: 'Exit' }
      ],
      bust_type: {
        entrance: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        snake: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        connector: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        vertical: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        mixer: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        exit: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ]
      }
    },
    free: {
      duration: 90
    }
  },
  freestyle_solo: {
    compulsory: {
      duration: 45
    },
    free: {
      duration_min: 75,
      duration_max: 90
    }
  },
  fs_4w: {
    standard: {
      round_time: 35
    }
  },
  fs_8w: {
    standard: {
      round_time: 50
    }
  },
  vfs_4w: {
    standard: {
      round_time: 35
    }
  }
}

function mapConfiguration(discipline: string, flightType: string): any {
  return configurationMap[discipline]?.[flightType] || {}
}

function convertToTeamLite(team: Team): TeamLite {
  const {
    is_unranked,
    qualifications_score,
    final_score,
    qualifications_ranking,
    final_ranking,
    ...teamLite
  } = team
  return teamLite
}

function createBattle(battle: any, discipline: string, index: string): Battle {
  const flightType = mapFlightType(battle.type)
  const teamsInBattle = Object.values(battle.teams).filter((team: any) => team.id !== '0')
  return {
    id: parseInt(battle.id),
    number: parseInt(index) + 1,
    flight_type: flightType,
    configuration: mapConfiguration(discipline, flightType),
    description: '',
    draw: [],
    teams: teamsInBattle.map((team: any) => ({
      id: parseInt(team.id),
      number: parseInt(team.num),
      name: team.name,
      coefficient: 1, // Not provided in Tunn3l data
      country: { code: team.country, name: '' },
      qualifications_score: null, // Not provided in Tunn3l data
      final_score: null, // Not provided in Tunn3l data
      qualifications_ranking: null, // Not provided in Tunn3l data
      final_ranking: null, // Not provided in Tunn3l data
      is_unranked: false // Not provided in Tunn3l data
    })),
    sub_battles: [], // TODO gérer les sub_battles pour isjudging ( A supprimer ? )
    flights: []
  }
}

function createFlight(
  eventId: number,
  roundId: number,
  battleId: number | null,
  flightNumber: number,
  team: Team | null,
  score: number | null,
  draw: []
): Flight {
  const team_lite = team ? convertToTeamLite(team) : null
  // Création d'un ID unique
  const provider_flight_id = battleId
    ? `${eventId}-${roundId}-${battleId}-${flightNumber}`
    : `${eventId}-${roundId}-${flightNumber}`
  return {
    id: provider_flight_id,
    round: roundId,
    battle: battleId,
    number: flightNumber,
    team: team_lite,
    score: score,
    draw: draw
  }
}

function updateTeamQualificationsScore(event: Event) {
  event.teams.forEach((team) => {
    const score = event.rounds
      .filter((round) => round.is_qualification || event.format_type === 'standard_rounds')
      .reduce((total, round) => {
        const flight = round.flights.find((f) => f.team?.id === team.id)
        return total + (flight?.score ? Number(flight.score) : 0)
      }, 0)

    team.qualifications_score = Number(score.toFixed(2))
  })
}

function updateTeamQualificationsRanking(event: Event) {
  const sortedTeams = [...event.teams].sort(
    (a, b) => (b.qualifications_score || 0) - (a.qualifications_score || 0)
  )

  sortedTeams.forEach((team, index) => {
    const originalTeam = event.teams.find((t) => t.id === team.id)
    if (originalTeam) {
      originalTeam.qualifications_ranking = index + 1
    }
  })
}

const findFlightScore = (
  scores: ScoreObject[],
  flightRoundId: string,
  matchId: string,
  teamId: string,
  flightType: string
): string | null => {
  if (flightType === 'speed') {
    const flightScore = scores.find(
      (score) =>
        score.round_id === flightRoundId &&
        score.match_id === matchId &&
        score.team_id === teamId &&
        score.comment.toLowerCase() === 'final_time'
    )
    return flightScore ? flightScore.score : null
  }
  return null
}

export function transformTunn3lData(
  data: any,
  provider: string,
  provider_url: string,
  provider_token: string,
  token: string
): Competition {
  const judges = data.judges
  const scores = data.scores
  const draws = data.draws
  const events: Event[] = Object.values(data.disciplines).map((discipline: any) => {
    const formatType = mapFormatType(discipline.type)
    const eventId = parseInt(discipline.id) as number

    const eventTeamsList: Team[] = Object.values(discipline.teams).map((team: any) => ({
      id: parseInt(team.id),
      number: parseInt(team.num),
      name: team.name,
      coefficient: 1,
      country: { code: team.country, name: '' },
      qualifications_score: null,
      final_score: null,
      qualifications_ranking: null,
      final_ranking: null,
      is_unranked: false
    }))

    const eventJudges = Object.values(judges)
      .filter((judge: any) => judge.discipline_id === eventId.toString() && judge.name !== '')
      .map((judge: any) => ({
        id: judge.id,
        name: judge.name,
        is_event_judge: judge.is_event_judge === '1' ? true : false
      }))

    const rounds: Round[] = Object.values(discipline.rounds)
      .filter((round: any) => round.type !== 'SC')
      .map((round: any, index: number) => {
        let round_qualification: boolean
        let round_tournament: boolean

        if (formatType === 'qualifications-tournament_bracket') {
          round_qualification = round.qualif === '1'
          round_tournament = round.qualif === '0'
        } else {
          round_qualification = true
          round_tournament = false
        }

        let battles: Battle[] = []
        let flights: Flight[] = []
        let configuration = {}

        if (formatType === 'qualifications-tournament_bracket' && round_tournament) {
          if (round.matchs) {
            battles = Object.entries(round.matchs).map(
              ([_, match]: [string, any], index: number) => {
                const battle = createBattle(
                  match as any,
                  discipline.type as string,
                  index.toString() as string
                )
                const teamsInBattle =
                  typeof match.teams === 'object' ? Object.values(match.teams) : []
                const battleJudgements = match.jugements

                battle.flights = teamsInBattle.map((teamData: any, index: number) => {
                  let score = null
                  if (battleJudgements && teamData && teamData.id !== '0') {
                    score = battleJudgements[teamData.id]
                      ? parseFloat(battleJudgements[teamData.id].score)
                      : null
                  }
                  return createFlight(
                    eventId,
                    parseInt(round.id),
                    battle.id,
                    index + 1,
                    teamData ? teamData : null,
                    score,
                    battle.draw || []
                  )
                })
                return battle
              }
            )
          }
        } else {
          configuration = mapConfiguration(discipline.type, mapFlightType(round.type))

          flights = eventTeamsList.map((team, index) => {
            const score = findFlightScore(
              scores,
              round.id.toString(),
              '0',
              team.id.toString(),
              mapFlightType(round.type)
            )
            return createFlight(
              eventId,
              parseInt(round.id),
              null,
              index + 1,
              team,
              score ? Number(score) : null,
              round.draw || []
            )
          })
        }

        // return the round object
        return {
          id: parseInt(round.id),
          number: index + 1,
          is_qualification: round_qualification,
          is_tournament: round_tournament,
          flight_type: mapFlightType(round.type),
          configuration: configuration,
          description: round.name ? round.name : 'Round ' + (index + 1),
          draw: round.draw || [],
          teams: [], // This will be populated later if needed
          flights: flights,
          battles: battles,
          event: eventId
        }
      })

    // return the event object
    const event: Event = {
      id: parseInt(discipline.id),
      name: discipline.name,
      discipline: mapDiscipline(discipline.type),
      category: '',
      format_type: formatType,
      teams: Object.values(discipline.teams).map((team: any) => ({
        id: parseInt(team.id),
        number: parseInt(team.num),
        name: team.name,
        coefficient: 1, // Not provided in Tunn3l data
        country: { code: team.country, name: '' },
        qualifications_score: null, // Not provided in Tunn3l data
        final_score: null, // Not provided in Tunn3l data
        qualifications_ranking: null, // Not provided in Tunn3l data
        final_ranking: null, // Not provided in Tunn3l data
        is_unranked: false // Not provided in Tunn3l data
      })),
      rounds: rounds,
      qualifications_sorting_round: null,
      event_judge: null,
      format: null,
      judges: eventJudges
    }

    updateTeamQualificationsScore(event)
    updateTeamQualificationsRanking(event)

    return event
  })

  // return the competition object
  return {
    id: parseInt(data.competition.id),
    name: data.competition.name,
    provider: provider,
    provider_url: provider_url,
    provider_token: provider_token,
    token: token,
    competition_id: data.competition.id,
    configuration: data.competition.configuration,
    dynamr_flights: data.competition.dynamr_flights,
    events: events
  }
}
