<script setup lang="ts">
import { ref } from 'vue'
import { Button } from '@/components/ui/button'
import { useDynamrLocalStore } from '@/stores/dynamrLocalStore'
import { useModalStore } from '@/stores/modalStore'
import notify from '@/services/notify'
const dynamrLocalStore = useDynamrLocalStore()
const modalStore = useModalStore()

const newDynamrLocal = ref(dynamrLocalStore.dynamr_local)

const isValidIpAddress = (ipAddress: string) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)
}

const isValidDomainName = (domainName: string) => {
  return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(domainName)
}

const updateDynamrLocal = () => {
  if (newDynamrLocal.value === '' || newDynamrLocal.value === null) {
    return
  }

  //check if the new dynamr local is a valid ip address or domain name or localhost
  if (!isValidIpAddress(newDynamrLocal.value) && !isValidDomainName(newDynamrLocal.value) && newDynamrLocal.value !== 'localhost') {
    notify('Invalid Dynamr Local IP Address or Domain Name', 'Please enter a valid IP address or domain name', 'error')
    return
  }

  dynamrLocalStore.setDynamrLocal(newDynamrLocal.value)
  modalStore.closeUpdateDynamrLocalModal()
  notify('Dynamr Local updated', 'Please reload the page', 'success')
}

const closeModal = () => {
  modalStore.closeUpdateDynamrLocalModal()
}
</script>

<template>
  <div
    v-if="modalStore.isUpdateDynamrLocalModalOpen"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white dark:bg-gray-800 rounded-lg p-6 w-100">
      <h2 class="text-xl mb-4 text-gray-800 dark:text-gray-100">Update Dynamr Local IP Address</h2>
      <input
        v-model="newDynamrLocal"
        type="text"
        class="w-full border rounded px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-primary"
        placeholder="Enter new value"
      />
      <p class="mt-2 mb-6 text-sm text-gray-500">Be sure to accept the new DynamR certificate <a class="text-blue-500" :href="`https://${newDynamrLocal}:8443/`" target="_blank">here</a></p>
      <div class="flex justify-end gap-2">
        <Button variant="secondary" @click="closeModal">Cancel</Button>
        <Button variant="default" @click="updateDynamrLocal" :disabled="newDynamrLocal === ''">Save</Button>
      </div>
    </div>
  </div>
</template>