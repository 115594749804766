// stores/dynamrLocalStore.ts
import { defineStore } from 'pinia'


export const useDynamrLocalStore = defineStore('dynamrLocalStore', {
  state: () => ({
    dynamr_local: localStorage.getItem('dynamr_local') as string | null,
  }),
  getters: {
    getDynamrLocal: (state) => state.dynamr_local,
  },
  actions: {
    setDynamrLocal(dynamr_local: string) {
      this.dynamr_local = dynamr_local
      localStorage.setItem('dynamr_local', dynamr_local)
    },
  }
})

