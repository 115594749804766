import Wampy from 'wampy'
import { ref } from 'vue'
import notify from '@/services/notify'

const isWampConnected = ref(false)
const isWampAvailable = ref(false)
const shouldDisplayWampError = ref(true)

//check if dynamr local is set in local storage
if (localStorage.getItem('dynamr_local') === null || localStorage.getItem('dynamr_local') === '') {
  localStorage.setItem('dynamr_local', 'dynamr.local')
}

const dynamr_local = localStorage.getItem('dynamr_local') || 'dynamr.local'

// if actual connexion is http, use ws, else use wss, same with the port
let protocol = 'ws'
let port = 8080
if (window.location.protocol === 'https:') {
  protocol = 'wss'
  port = 8443
}

const wamp = new Wampy(protocol + '://' + dynamr_local + ':' + port + '/ws/', {
  realm: 'dynamr',
  reconnectInterval: 5000,
  maxRetries: 1,
  onClose: () => {
    isWampConnected.value = false
    if (isWampAvailable.value === true && shouldDisplayWampError.value === true) {
      notify('Connection with Dynamr closed', 'trying to reconnect...', 'error')
      shouldDisplayWampError.value = false
      wamp.connect()
    }
  },
  onError: () => {
    isWampConnected.value = false
    if (isWampAvailable.value === true && shouldDisplayWampError.value === true) {
      notify('Connection with Dynamr lost', 'trying to reconnect...', 'error')
      shouldDisplayWampError.value = false
    }
  },
  onReconnectSuccess: () => {
    isWampConnected.value = true
    notify('Connection with Dynamr restored', undefined, 'success')
  }
})

async function wampConnect() {
  try {
    await wamp.connect()
    console.log('Connected to Dynamr Judging System')
    isWampAvailable.value = true
    isWampConnected.value = true
    notify('Connected to Dynamr Judging System', undefined, 'success')
  } catch (error: any) {
    if (error.name === 'WebsocketError' && error.message === 'Websocket error') {
      notify('Unable to connect to Dynamr', 'Could be a problem with the certificate, did you accept the new certificate ?', 'error')
    }
    if (isWampAvailable.value === true) {
      notify('Dynamr is not available', 'WAMP connection failed', 'error')
    }
  }
}

async function wampCall(name: string, args = [] as any, kwargs = {}) {
  if (isWampConnected.value === false && isWampAvailable.value === false) {
    notify('Dynamr is not reachable, please check your network and try again.', undefined, 'error')
    return { error: 'Dynamr is not reachable, please check your network and try again.' }
  }

  try {
    const response = await wamp.call('com.dynamr.' + name, args, kwargs)
    // @ts-ignore
    const res = response.argsList[0]
    if (res !== null && typeof res === 'object' && res.status === 'error') {
      notify(res.details, undefined, 'info')
      return { res }
    }
    return res
  } catch (error) {
    if (shouldDisplayWampError.value === true) {
      notify('DynamR call failed...', 'Server Down ? ', 'error')
      shouldDisplayWampError.value = false
    }
    throw error
  }
}

function wampControllerCall(...args: any) {
  return wampCall('controller.call', args)
}

function waitForWampConnection(timeout = 15000): Promise<void> {
  return new Promise((resolve, reject) => {
    if (isWampConnected.value === true) {
      resolve()
      return
    }

    const startTime = Date.now()
    const checkConnection = () => {
      if (isWampConnected.value === true) {
        resolve()
      } else if (Date.now() - startTime > timeout) {
        reject(new Error('Timeout en attendant la connexion WAMP'))
      } else {
        setTimeout(checkConnection, 5000)
      }
    }

    checkConnection()
  })
}

// Ajout de la détection de connexion réseau du navigateur
window.addEventListener('online', () => {
  if (isWampAvailable.value && !isWampConnected.value) {
    wampConnect()
  }
  notify('Connection with Dynamr restored')
})

window.addEventListener('offline', () => {
  isWampConnected.value = false
  if (isWampAvailable.value) {
    notify('Connection with Dynamr lost, will try to reconnect when online')
  }
})

export {
  wamp,
  isWampConnected,
  isWampAvailable,
  wampConnect,
  wampCall,
  wampControllerCall,
  waitForWampConnection
}
