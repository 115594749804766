// stores/authStore.ts
import { defineStore } from 'pinia'
import { AuthService } from '@/services/auth'
import axiosInstance from '@/utils/axios'
import { useRouter } from 'vue-router'

import type { Email, User } from '@/interfaces/user'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: null as User | null,
    token: localStorage.getItem('token') as string | null,
    refreshToken: localStorage.getItem('refreshToken') as string | null
  }),
  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getRefreshToken: (state) => state.refreshToken,
    getPrimaryEmailOnly: (state) => {
      const primaryEmail = state.user?.emails?.find((email) => email.primary)
      if (primaryEmail) {
        if (primaryEmail.email !== state.user?.primary_email) {
          throw new Error('Primary email mismatch')
        }
        return primaryEmail.email
      } else if (state.user?.primary_email) {
        return state.user.primary_email
      }
      return null
    },
    getVerifiedEmailsOnly: (state) =>
      state.user?.emails?.filter((email) => email.verified && !email.primary),
    getUnverifiedEmailsOnly: (state) => state.user?.emails?.filter((email) => !email.verified)
  },
  actions: {
    setToken(token: string) {
      this.token = token
      localStorage.setItem('token', token)
    },
    setRefreshToken(refreshToken: string) {
      this.refreshToken = refreshToken
      localStorage.setItem('refreshToken', refreshToken)
    },
    async signup(email: string, password: string) {
      try {
        const response = await AuthService.signup(email, password)
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async activate(uid: string, token: string) {
      try {
        const response = await AuthService.activate(uid, token)
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async login(email: string, password: string, loginMethod: 'password' | 'email_code') {
      try {
        const response = await AuthService.login(email, password, loginMethod)
        this.setToken(response.data.access)
        this.setRefreshToken(response.data.refresh)
        await this.fetchUser()
        return response.data.message
      } catch (error: any) {
        console.log(error)
        if (error.response && error.response.data && error.response.data.error) {
          throw error.response.data.error
        } else {
          throw 'Please try again or contact support if the problem persists.'
        }
      }
    },
    async loginWithCode(email: string, code: string) {
      try {
        const response = await AuthService.loginWithCode(email, code)
        this.token = response.data.access
        this.refreshToken = response.data.refresh
        await this.fetchUser()
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async requestLoginCode(email: string) {
      try {
        const response = await AuthService.requestLoginCode(email)
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    eraseLocalTokens() {
      this.user = null
      this.token = null
      this.refreshToken = null
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
    },
    async checkAuth() {
      if (!this.token || !this.refreshToken) {
        try {
          this.token = localStorage.getItem('token') as string
          this.refreshToken = localStorage.getItem('refreshToken') as string
          if (!this.token || !this.refreshToken) {
            this.eraseLocalTokens()
            return false
          }
        } catch (error: any) {
          this.eraseLocalTokens()
          return false
        }
      }
      try {
        await this.fetchUser()
        return true
      } catch (error: any) {
        this.eraseLocalTokens()
        return false
      }
    },
    async verifyEmail(key: string) {
      try {
        const response = await AuthService.verifyEmail(key)
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async fetchUser() {
      try {
        const response = await axiosInstance.get('user/')
        this.user = response.data
        return this.user
      } catch (error: any) {
        throw error.response.data
      }
    },
    async fetchUserProfile() {
      try {
        const response = await axiosInstance.get('user/profile/')
        this.user = response.data
        return this.user
      } catch (error: any) {
        throw error.response.data
      }
    },
    async updateUserProfile(profileData: any) {
      try {
        const response = await axiosInstance.put('user/profile/', profileData)
        this.user = response.data.user
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async updateUserPassword(passwordData: any) {
      try {
        const response = await axiosInstance.post('user/change-password/', passwordData)
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async addEmail(email: string) {
      try {
        const response = await axiosInstance.post('user/add-email/', { email })
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async deleteEmail(email: string) {
      try {
        const response = await axiosInstance.post('user/delete-email/', { email })
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async setPrimaryEmail(email: string) {
      try {
        const response = await axiosInstance.post('user/set-primary-email/', { email })
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async resendVerificationCode(email: string) {
      try {
        const response = await axiosInstance.post('user/send-verification-code/', { email })
        return response.data.message
      } catch (error: any) {
        throw error.response.data
      }
    },
    async fetchCountries() {
      try {
        const response = await axiosInstance.get('countries/')
        return response.data
      } catch (error: any) {
        throw error.response.data
      }
    },
    async checkUsername(username: string) {
      try {
        const response = await axiosInstance.post(`user/check-username/`, {
          username
        })
        return response.data.exists
      } catch (error: any) {
        throw error.response.data
      }
    }
  }
})
