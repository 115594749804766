// stores/competitionStore.ts

import { defineStore } from 'pinia'
import type { CompetitionLite } from '@/interfaces/competition'

import { CompetitionManager } from '@/utils/competitionManager'
import { transformTunn3lData } from '@/transformers/tunn3l'

import tunn3lApi from '@/services/tunn3lApi'
import isJudgingApi from '@/services/isJudgingApi'
import notify from '@/services/notify'

import { waitForWampConnection, wampCall } from '@/services/wamp'

import { useJudgementStore } from './judgementStore'

export const useCompetitionStore = defineStore('competitionStore', {
  state: () => ({
    competitions: [] as CompetitionLite[] | [],
    activeCompetition: null as CompetitionManager | null,
    loading: false as boolean,
    loadingT3comp: false as boolean,
    loadingIScomp: false as boolean,
    loadingActive: false as boolean,
    error: null as null | Error,
    initialized: false as boolean
  }),

  getters: {
    getTunn3lCompetitions: (state) =>
      state.competitions.filter((comp) => comp.provider === 'tunn3l'),
    getISJudgingCompetitions: (state) =>
      state.competitions.filter((comp) => comp.provider === 'isjudging'),
    getActiveCompetition: (state) => state.activeCompetition,
    getTeam: (state) => (teamId: number) => {
      return state.activeCompetition?.getTeam(teamId)
    }
  },

  actions: {
    async init() {
      if (this.initialized) return
      this.loading = true
      this.loadingT3comp = true
      this.loadingIScomp = true
      try {
        await waitForWampConnection()
        this.initialized = true
        this.loading = false
        await Promise.all([this.fetchTunn3lCompetitions(), this.fetchISJudgingCompetitions()])
      } catch (error: any) {
        console.error("Erreur lors de l'initialisation du Competition Store:", error)
        this.error = error
        this.loading = false
        throw error
      }
    },

    async fetchTunn3lCompetitions() {
      this.loadingT3comp = true
      this.competitions = this.competitions.filter((comp) => comp.provider !== 'tunn3l')
      try {
        const dynamrCompetitions: Record<string, CompetitionLite> = (await wampCall(
          'competition.all'
        )) as Record<string, CompetitionLite>

        const tunn3lCompetitions = Object.values(dynamrCompetitions).filter(
          (comp) => comp.provider === 'tunn3l'
        )

        const promises = tunn3lCompetitions.map(async (competition) => {
          try {
            if (!competition.provider_url || !competition.provider_token) {
              console.error('Provider url or token not found')
              throw new Error('Provider url or token not found')
            }
            const { data } = await tunn3lApi.getCompetition(
              competition.provider_url,
              competition.provider_token,
              competition.token
            )
            const detailedCompetition = data.results

            return {
              ...competition,
              name: String(detailedCompetition.competition.name),
              fetched: true // Added fetched attribute
            } as CompetitionLite
          } catch (error) {
            console.error(
              `Erreur lors de la récupération des détails pour la compétition ${competition.id}:`,
              error
            )
            return {
              ...competition,
              fetched: false // Added fetched attribute to false on error
            } as CompetitionLite
          }
        })

        const detailedCompetitions = await Promise.all(promises)
        this.competitions.push(...detailedCompetitions)
      } catch (error: any) {
        notify('Fetching Tunn3l competitions failed', 'Server Down?', 'error')
        this.error = error
        throw error
      } finally {
        this.loadingT3comp = false
      }
    },

    async fetchISJudgingCompetitions() {
      this.loadingIScomp = true
      this.competitions = this.competitions.filter((comp) => comp.provider !== 'isjudging')
      try {
        const dynamrCompetitions: Record<string, CompetitionLite> = (await wampCall(
          'competition.all'
        )) as Record<string, CompetitionLite>

        const isJudgingCompetitions = Object.values(dynamrCompetitions).filter(
          (comp) => comp.provider === 'isjudging'
        )

        const promises = isJudgingCompetitions.map(async (competition) => {
          try {
            const { data } = await isJudgingApi.getCompetition(Number(competition.token))
            return {
              ...competition,
              name: String(data.name),
              fetched: true // Added fetched attribute
            } as CompetitionLite
          } catch (error) {
            console.error(
              `Erreur lors de la récupération des détails pour la compétition ${competition.id}:`,
              error
            )
            return {
              ...competition,
              fetched: false // Added fetched attribute to false on error
            } as CompetitionLite
          }
        })

        const detailedCompetitions = await Promise.all(promises)
        this.competitions.push(...detailedCompetitions)
      } catch (error: any) {
        notify('Fetching IsJudging competitions failed', 'Server Down?', 'error')
        this.error = error
        throw error
      } finally {
        this.loadingIScomp = false
      }
    },

    async fetchActiveCompetition(provider: string, competition_token: string) {
      this.loadingActive = true
      const competitionDetails = this.competitions?.find(
        (comp) => comp.provider === provider && comp.token === competition_token
      )
      if (
        !competitionDetails ||
        (competitionDetails.provider === 'tunn3l' && !competitionDetails.provider_url) ||
        (competitionDetails.provider === 'tunn3l' && !competitionDetails.provider_token)
      ) {
        console.error('Provider url or token not found')
        this.loadingActive = false
        throw new Error('Provider url or token not found')
      }

      const { provider_url, provider_token, token } = competitionDetails

      try {
        let competition_datas = null

        switch (provider) {
          case 'tunn3l': {
            if (!provider_url || !provider_token) {
              console.error('Provider url or token not found')
              this.loadingActive = false
              throw new Error('Provider url or token not found')
            }
            const response = await tunn3lApi.getCompetition(provider_url, provider_token, token)
            const t3_data = response.data

            competition_datas = transformTunn3lData(
              t3_data.results,
              'tunn3l',
              provider_url,
              provider_token,
              token
            )
            // console.log(
            //   'competition_datas from ' +
            //     provider +
            //     ' : ' +
            //     JSON.stringify(competition_datas, null, 2)
            // )

            this.activeCompetition = new CompetitionManager(competition_datas)
            this.error = t3_data.error
            break
          }

          case 'isjudging': {
            const response = await isJudgingApi.getCompetition(Number(token))
            const ij_data = response.data

            competition_datas = {
              // ...ij_data,
              id: parseInt(ij_data.id),
              name: ij_data.name,
              configuration: ij_data.configuration,
              dynamr_flights: ij_data.dynamr_flights,
              provider: 'isjudging',
              competition_id: ij_data.id,
              provider_url: '',
              provider_token: '',
              token: ij_data.id,
              events: ij_data.events
            }

            this.activeCompetition = new CompetitionManager(competition_datas)
            this.error = ij_data.error
            break
          }
          default:
            console.error('Provider not found')
        }
      } catch (error) {
        notify('Fetching comppetition failed', 'Server Down ? ', 'error')
        this.error = error as Error
        throw error
      } finally {
        this.loadingActive = false
      }
    },

    async refreshCompetitionData() {
      if (!this.activeCompetition) {
        return
      }

      try {
        this.fetchActiveCompetition(
          this.activeCompetition.getCompetitionData().provider,
          this.activeCompetition.getCompetitionData().token
        )
      } catch (error) {
        notify('Fetching competition failed', 'Server Down ? ', 'error')
        this.error = error as Error
        throw error
      }
    },

    async createFlight(provider_flight_id: string) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }

      const flight = this.activeCompetition.getFlightInfo(provider_flight_id)
      if (!flight) {
        throw new Error('Flight not found')
      }

      const eventJudges = this.activeCompetition
        .getCompetitionData()
        .events.find((event) => event.id === flight.event_id)?.judges as Record<
        string,
        { name: string }
      >

      const judges = eventJudges

      const config = {
        event: {
          provider: this.activeCompetition.getCompetitionData().provider,
          provider_url: this.activeCompetition.getCompetitionData().provider_url,
          provider_token: this.activeCompetition.getCompetitionData().provider_token,
          token: this.activeCompetition.getCompetitionData().token,
          competition_id: flight.competition_id,
          event_id: flight.event_id
        },
        round_id: flight.round,
        battle_id: flight.battle,
        round_number: flight.round_number,
        battle_number: flight.battle_number,
        team: flight.team,
        provider_flight_id: provider_flight_id,
        discipline: flight.discipline,
        flight_type: flight.flight_type,
        judges: judges,
        draw: flight.draw,
        ...flight.configuration, // Ajoute toutes les clés/valeurs de flight.configuration
        ...(flight.configuration.bust_value !== undefined && {
          bust: flight.configuration.bust_value // Ajoute bust si bust_value existe
        }),
        ...(flight.configuration.skip_value !== undefined && {
          skip: flight.configuration.skip_value // Ajoute skip si skip_value existe
        })
      }

      // // wampControllerCall('round_' + flight.flight_type, 'create', configuration)
      wampCall('competition.dist.round.create', [config])
    },

    async createFreeBattleJudgement(eventId: number, roundId: number, battleId: number) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }
      const eventJudges = this.activeCompetition
        .getCompetitionData()
        .events.find((event) => event.id === eventId)?.judges

      const teams = this.activeCompetition
        .getCompetitionData()
        .events.find((event) => event.id === eventId)
        ?.rounds.find((round) => round.id === roundId)
        ?.battles.find((battle) => battle.id === battleId)?.teams

      useJudgementStore().createFreeBattleJudgement(eventId, roundId, battleId, eventJudges, teams)
    }
  }
})
