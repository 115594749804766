<script setup lang="ts">
import { onMounted } from 'vue'
import { useAppStatusStore } from '@/stores/appStatusStore'
import { useSpeedRoundStore } from '@/stores/speedRoundStore'
import { useFreeRoundStore } from '@/stores/freeRoundStore'

const speedRoundStore = useSpeedRoundStore()
const freeRoundStore = useFreeRoundStore()
</script>

<template>
  {{ speedRoundStore.stopwatch }}
</template>
