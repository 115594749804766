import { defineStore } from 'pinia'

import { wampControllerCall } from '@/services/wamp'

const CONTROLLER_NAME = 'judgement'

export const useJudgementStore = defineStore('judgement', {
  state: () => ({
    status: null as string | null,
    trigger: null as string | null,
    configuration: {} as any,
    votes: [] as any,
    winner: [] as any,
    details: [] as any
    // judges: [] as any,
    // teams: [] as any
  }),
  getters: {
    getJudges: (state) => state.configuration.judges || [],
    getTeams: (state) => state.configuration.teams || []
  },
  actions: {
    reset() {
      this.status = null
      this.trigger = null
      this.configuration = {}
      this.votes = []
      this.winner = []
      this.details = []
      // this.judges = []
      // this.teams = []
    },
    fetchAppStatus(data: any, configuration: any, current: any, status: string, trigger: string) {
      console.log('fetchAppStatus from judgement, DATA', data)
      console.log('fetchAppStatus from judgement, CONFIGURATION', configuration)
      console.log('fetchAppStatus from judgement, CURRENT', current)
      console.log('fetchAppStatus from judgement, STATUS', status)
      console.log('fetchAppStatus from judgement, TRIGGER', trigger)

      this.status = status
      this.trigger = trigger
      this.votes = data.votes
      this.winner = data.winner
      this.details = data.details
      this.configuration = data.configuration
    },

    handleEvent(data: any, status: string, trigger: string) {
      console.log('handleEvent from judgement, DATA', data)
      console.log('handleEvent from judgement, STATUS', status)
      console.log('handleEvent from judgement, TRIGGER', trigger)
      switch (trigger) {
        case 'start':
          this.status = status
          this.trigger = trigger
          this.votes = data.votes
          this.winner = data.winner
          this.details = data.details
          this.configuration = data.configuration
          break
        case 'stop':
          this.status = status
          this.trigger = trigger
          this.votes = data.votes
          this.winner = data.winner
          this.details = data.details
          this.configuration = data.configuration
          break
      }
    },

    createFreeBattleJudgement(
      eventId: number,
      roundId: number,
      battleId: number,
      judges: any,
      teams: any
    ) {
      wampControllerCall('judgement_ng', 'start', {
        secret: false,
        judges: judges,
        teams: teams
      })
    },

    vote(judge: any, ranking: any) {
      wampControllerCall('judgement_ng', 'vote', judge, ranking)
    },

    stopFreeBattleJudgement() {
      wampControllerCall('judgement_ng', 'stop', {})
    }
  }
})
