<script setup lang="ts"></script>

<template>
  <div class="container mx-auto p-4 text-center">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="space-y-4">
        <RouterLink to="/competitions" class="block">
          <div class="text-4xl bg-primary p-12 rounded-lg">
            <h1>Competitions</h1>
          </div>
        </RouterLink>
        <RouterLink to="/training" class="block">
          <div class="text-2xl bg-secondary p-6 rounded-lg">
            <h1>Training</h1>
          </div>
        </RouterLink>
      </div>
      <div class="space-y-4 md:mt-0 mt-4">
        <RouterLink to="/status" class="block">
          <div class="text-2xl bg-accent p-8 rounded-lg">
            <h1>Status</h1>
          </div>
        </RouterLink>
        <RouterLink to="/settings" class="block">
          <div class="text-2xl bg-destructive p-10 rounded-lg">
            <h1>Settings</h1>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
