import { defineStore } from 'pinia'
import { startStopWatch, stopStopWatch, resetStopWatch } from '@/services/stopWatch'

import { wampCall, wampControllerCall } from '@/services/wamp'

import tunn3lApi from '@/services/tunn3lApi'
import notify from '@/services/notify'

import { videoClient } from '@/services/videoClient'

// import { useCompetitionStore } from './competitionStore'

interface Point {
  busts: Record<string, boolean>
  time: number
  type: string | null
}

// Utilisation du option store
export const useFormationSkydivingStore = defineStore('formationSkydiving', {
  state: () => ({
    status: null as string | null,
    trigger: null as string | null,
    configuration: {} as any,
    stopwatch: '0.000' as string,

    times: {} as any,
    metas: {} as any,
    startDelay: 0 as number,

    dynamr_flight_id: null as number | null,

    // judges: null as [] | null, // Dans la configuration et en Base ?

    points: null as Point[] | null,
    details: null as [] | null,
    score: null as number | null,
    is_posted: false as boolean
  }),
  getters: {
    getBustCount() {
      if (this.details !== undefined && this.details !== null) {
        // const count: number = this.details.filter((busted: boolean) => busted === true).length
        let count = 0
        for (const busted of this.details) {
          if (busted === true) {
            count++
          }
        }
        return count
      }
      return null
    }
  },
  actions: {
    reset() {
      ;(this.status = null),
        (this.trigger = null),
        (this.configuration = {}),
        (this.stopwatch = '0.000'),
        (this.times = {}),
        (this.metas = {}),
        (this.startDelay = 0),
        (this.dynamr_flight_id = null),
        (this.points = null),
        (this.details = null),
        (this.score = null),
        (this.is_posted = false)
    },

    fetchAppStatus(data: any, configuration: any, current: any, status: string, trigger: string) {
      this.status = status
      this.trigger = trigger
      this.configuration = configuration

      this.times = data.times
      this.metas = data.metas
      this.startDelay = data.start_delay

      this.dynamr_flight_id = data.id

      this.points = data.points
      this.details = data.details
      this.score = data.score
      this.is_posted = data.is_posted
    },

    toggleJudgeBust(index: number, who: string) {
      if (this.points) {
        console.log('toggleJudgeBust', index, who)
        console.log(this.points)
        console.log(this.points[index].busts)
        console.log(this.points[index].busts[who])
        this.points[index].busts[who] = !this.points[index].busts[who]
        this.refreshResults()
        this.save()
      }
    },

    toggleBust(index: number) {
      if (this.points && this.details) {
        for (const who in this.configuration.judges) {
          this.points[index].busts[who] = !this.details[index]
        }
        this.refreshResults()
        this.save()
        // this.points[index].busts = !this.points[index].busts
      }
    },

    setBustType(index: number, value: string | null) {
      if (this.points) {
        this.points[index].type = value
        this.refreshResults()
        this.save()
      }
    },

    calculateAdjustedTime(timeFromVideo: number) {
      if (isNaN(timeFromVideo)) {
        return this.configuration.round_time - 0.01
      }
      console.log('calculateAdjustedTime', this.startDelay)
      console.log(
        'calculateAdjustedTime',
        Math.round((timeFromVideo - this.startDelay) * 100) / 100
      )
      return Math.min(
        Math.max(Math.round((timeFromVideo - this.startDelay) * 100) / 100, 0.0),
        this.configuration.round_time - 0.01
      )
    },

    handleAddPoint(adjustedTime: number) {
      if (!this.points) this.points = []
      this.points.push({
        busts: {},
        time: adjustedTime,
        type: null
      })
      this.points.sort((a, b) => a.time - b.time)
      this.refreshResults()
      this.save()
    },

    async addPoint() {
      await videoClient
        .time('relative_work_ng')
        .then((timeFromVideo: number) => {
          console.log('addPoint', timeFromVideo)
          const adjustedTime = this.calculateAdjustedTime(timeFromVideo)
          console.log('addPoint', adjustedTime)
          this.handleAddPoint(adjustedTime)
        })
        .catch((error: any) => {
          const defaultTime = this.configuration.round_time - 0.01
          this.handleAddPoint(defaultTime)
          notify(
            'Failed to get video time',
            'setting new point to ' + defaultTime + ' seconds.',
            'info'
          )
        })
    },

    save(posted: boolean = false, review: boolean = false) {
      this.is_posted = posted
      if (this.dynamr_flight_id !== null) {
        const result = wampControllerCall('relative_work_ng', 'save', {
          id: this.dynamr_flight_id,
          points: this.points,
          has_entry_fault: false,
          is_posted: this.is_posted,
          review: review
        })
        if ('then' in result) {
          result.then(() => {
            if (this.is_posted) {
              notify('Score has be posted', '', 'success')
            }
          })
        } else {
          notify('Error saving', (result as { error: string }).error, 'error')
        }
      } else {
        notify('Cannot save: Invalid flight ID', '', 'error')
      }
    },

    refreshResults() {
      const judgeCount = Object.keys(this.configuration.judges).length
      const entry_fault_penalty = this.configuration.entry_fault
        ? this.configuration.entry_fault
        : 0
      const result = wampCall('sheet.relative_work_ng.calculate_result', [
        this.points as Point[],
        judgeCount as number,
        false as boolean, // hasEntryFault
        entry_fault_penalty as number
      ])
      console.log('refreshResults', result)
      if (result instanceof Promise) {
        result
          .then((response: any) => {
            this.details = response.details
            this.score = response.score
          })
          .catch((error: any) => {
            notify('Error calculating result', error, 'error')
          })
      } else if (typeof result === 'object' && result !== null && 'error' in result) {
        notify('Error calculating result', (result as { error: string }).error, 'error')
      }
    },

    async publishScores() {
      if (this.configuration.event && this.configuration.event.provider === 'tunn3l') {
        await tunn3lApi
          .uploadScores(
            this.configuration.event.provider_url,
            this.configuration.event.provider_token,
            this.configuration.event.token,
            this.configuration.event.competition_id,
            this.configuration.event.event_id,
            this.configuration.round_id,
            this.configuration.battle_id,
            this.configuration.team.id,
            this.score || null
          )
          .then(() => {
            notify('Score uploaded', '', 'success')
          })
          .catch((error: any) => {
            notify('Score upload failed', error, 'error')
          })
      }
    },

    handleEvent(data: any, status: string, trigger: string) {
      switch (trigger) {
        case 'create':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'activate':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'start':
          if (status === 'running' && this.status === 'active') {
            startStopWatch((time: string) => {
              this.stopwatch = time
            })
          }
          this.points = data.points
          this.details = data.details
          this.score = data.score
          this.status = status
          this.trigger = trigger
          break
        case 'point_validate':
        case 'bust':
          this.status = status
          this.trigger = trigger
          this.points = data.points
          this.details = data.details
          this.score = data.score
          break
        case 'stop':
          this.status = status
          stopStopWatch()
          this.trigger = trigger
          this.times = data.times
          this.metas = data.metas
          this.startDelay = data.start_delay
          this.dynamr_flight_id = data.id
          this.points = data.points
          this.details = data.details
          this.score = data.score
          this.is_posted = data.is_posted
          // Upload scores as unofficial // Maybe later
          // if (this.configuration.event) {
          //   this.publishScores().then(() => {
          //   useCompetitionStore().refreshCompetitionData()
          // })
          // }
          break
      }
    }
  }
})
